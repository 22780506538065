import {  PERSONAL_DATA, 
          SET_LOADING, 
} from '../actions/personal';

const initState = {
  allData: [],
  loading: false,
}

const personalReducer = (state = initState, action) => {
  switch (action.type) {
    case PERSONAL_DATA:
      return {
        ...state,
        allData: action.payload,
        loading: false,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}

export default personalReducer;