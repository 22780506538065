import Axios from 'axios';

export const PERSONAL_DATA = 'PERSONAL REDUCER PERSONAL DATA';
export const SET_LOADING = 'SET REDUCER LOADING';

export const getPersonalData = () => async dispatch => {
  dispatch(setLoading(true));
  const personalData = await Axios.request({
    url: 'https://dcirrus.co.in/api.acms/v1/app/unindexfolderlistg/0/zerolevel/0/S/fetchAllAdmFolderListResponse',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
    withCredentials: true,
  });

  dispatch(setLoading(false));
  if(!personalData.data.error)
		dispatch({
			type: PERSONAL_DATA,
			payload: personalData.data.object,
		});
  else
		dispatch({
			type: PERSONAL_DATA,
			payload: 'error',
		});
}

export const setLoading = (value) => {
  return {
    type: SET_LOADING,
    payload: value,
  };
};