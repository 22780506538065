import React from 'react'

function PageLoader() {
  return (
    <div>
      Loading...
    </div>
  );
}

export default PageLoader;